<template>
    <div>
        <OrgAndNumber module='user'></OrgAndNumber>
    </div>
</template>
<script>
import OrgAndNumber from './components/orgAndNumber.vue'
export default {
    components:{
        OrgAndNumber
    }
}
</script>